import React from "react"
import Slider from "react-slick"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import siteSettings from "../../site-settings.json"

const Featured = ({ articles }) => (
  <Slider arrows autoplay>
    {articles.map(({ node }) => (
      <div>
        <div className="columns is-vcentered">
          <div className="column is-5">
            <div className="px-6 is-relative">
              <div>
                <h2 className="title is-family-secondary is-size-4 is-uppercase has-text-weight-normal has-background-white is-inline">
                  <Link to={node.fields.slug} key={node.id}>
                    {node.frontmatter.title}
                  </Link>
                </h2>
              </div>
              <br />
              <div className="content">
                <p className="is-size-6">{node.frontmatter.excerpt}</p>
              </div>
            </div>
          </div>
          <div className="column is-7">
            <figure className="image">
              <img alt={node.frontmatter.title} src={node.frontmatter.cover} />
            </figure>
          </div>
        </div>
      </div>
    ))}
  </Slider>
)

const MostRecent = ({ articles }) => {
  return (
    <div className="is-relative">
      {articles.map(({ node }) => (
        <div>
          <div className="columns is-vcentered">
            <div className="column is-7">
              <div className="px-6 is-relative">
                <div>
                  <h2 className="title is-family-secondary is-size-3 is-uppercase has-text-weight-normal has-background-white is-inline">
                    <Link to={node.fields.slug} key={node.id}>
                      {node.frontmatter.title}
                    </Link>
                  </h2>
                </div>
                <br />
                <div className="content">
                  <p className="is-size-5">{node.frontmatter.excerpt}</p>
                </div>
              </div>
            </div>
            <div className="column is-5">
              <figure className="image">
                <img
                  alt={node.frontmatter.title}
                  src={node.frontmatter.cover}
                />
                {/* <img src="https://picsum.photos/800/400" alt=""/> */}
              </figure>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Home" />
    <div className="container">
      <div className="hero">
        <div className="hero-body">
          <Featured articles={data.allMarkdownRemark.edges} />
        </div>
      </div>
    </div>
    <div className="container">
      <div className="section"></div>
      <div className="columns is-centered">
        <div className="column is-10-desktop is-12-touch">
          <MostRecent articles={data.allMarkdownRemark.edges} />
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { contentType: { eq: "article" } } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 10
    ) {
      edges {
        node {
          id
          excerpt
          frontmatter {
            title
            slug
            cover
            excerpt
            date(formatString: "DD/MM/YYYY")
            contentType
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
